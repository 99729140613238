import { provideIntoWindow } from "@b2c/core/app/default/provide-into-window.js";
import { brandConfig } from "../index.js";
import { $_ALTENARCONFIG } from "./../altenar.mjs";

const productionConfig = {};

provideIntoWindow("$_ALTENARCONFIG", $_ALTENARCONFIG);

export const prod = provideIntoWindow(
    "$_CONFIG",
    Object.assign({}, brandConfig, productionConfig)
);